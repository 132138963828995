import { Select } from 'antd';
import React, { FC } from 'react';
import { getCampaigns } from 'service/WorkspaceService';
import useSWR from 'swr';

const { Option } = Select;

// const handleChange = (id: number) => {
//   console.log('campaign: %o', id);
// };

type onChange = (id: number) => {};

export interface Props {
  onChange?: onChange;
  className?: string;
}

const CampaignFilter: FC<Props> = ({ onChange, className }) => {
  const { data, error } = useSWR('/api/archives/campaigns', getCampaigns);

  if (error) return <div>{error}</div>;
  if (!data) return <div></div>;

  return (
    <Select
      placeholder="Campaign"
      style={{ width: 250 }}
      onChange={onChange}
      allowClear
      className={className}
    >
      {data.map(campaign => {
        const { id, name } = campaign;
        return (
          <Option key={id} value={id}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
};
export default CampaignFilter;
