import BackButton from 'components/nav/BackButton';
import dayjs from 'dayjs';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import overflowHidden from 'styles/overflow-hidden';
import Archive from 'types/Archive';

const Header = styled.div`
  width: 100%;
`;

const Back = styled(BackButton)`
  position: absolute;
  top: 15px;
  left: 15px;
`;

const headerFont = `
  color: #ffffffb3;
  font-size: 1em;
  line-height: 38px;
`;

const Title = styled.h1`
  width: 100%;
  background: linear-gradient(to bottom, #272727 1%, #222 100%);
  border-bottom: 1px solid #424242;

  margin: 0;
  margin-bottom: 1px;
  text-indent: 80px;
  font-weight: normal;

  display: inline-block;

  ${headerFont}
`;

const SubHeader = styled.h2`
  text-indent: 80px;
  width: 100%;
  background: linear-gradient(to bottom, #041727 0, #062b3f 100%);

  margin: 0;
  color: #ffffffb3;
  font-weight: normal;
  font-size: 0.8em;
  line-height: 38px;
  display: inline-block;

  ${overflowHidden}
`;

const Submitted = styled.div`
  width: 220px;
  text-align: right;
  position: absolute;
  top: 2px;
  right: 15px;

  ${headerFont}
`;

interface Props {
  archive: Archive;
}

export default function ArchiveHeader({ archive }: Props): ReactElement {
  const { doi, citation, submitted } = archive;

  const formattedSubmitted = dayjs(submitted).format('M/DD/YYYY');

  return (
    <Header>
      <Back />
      <Title>{doi}</Title>
      <SubHeader>{citation}</SubHeader>
      <Submitted>Last submitted: {formattedSubmitted}</Submitted>
    </Header>
  );
}
