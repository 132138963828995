import Archive from 'types/Archive';
import { Campaign } from 'types/Campaign';
import File from 'types/File';
import { Organization } from 'types/Organization';
import { getJSON } from './AxiosService';

export const getArchive = async (id: string): Promise<Archive> => {
  const url = `https://researchworkspace.com/api/archive/${id}`;

  return getJSON(url);
};

export const getObsoleteFiles = async (id: string): Promise<File[]> => {
  const url = `https://researchworkspace.com/api/archive/${id}/files/obsolete`;

  return getJSON(url);
};

export const getArchives = async (): Promise<Archive> => {
  const url = 'https://researchworkspace.com/api/archives';
  return getJSON(url);
};

export const getCampaigns = async (): Promise<Campaign[]> => {
  const url = 'https://researchworkspace.com/api/archives/campaigns';
  return getJSON(url);
};

export const getOrganizations = async (): Promise<Organization[]> => {
  const url = 'https://researchworkspace.com/api/archives/orgs';
  return getJSON(url);
};
