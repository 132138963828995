import ArchiveTable from 'components/archives/ArchiveTable';
import ArchiveDiff from 'components/diff/ArchiveDiff';
import MetadataDiff from 'components/diff/MetadataDiff';
import { createBrowserHistory } from 'history';
import React, { ReactElement } from 'react';
import 'react-hot-loader';
import { setConfig } from 'react-hot-loader';
import { hot } from 'react-hot-loader/root';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
const history = createBrowserHistory();

setConfig({
  showReactDomPatchNotification: false
});

function App(): ReactElement {
  return (
    <>
      <Router history={history}>
        <Route exact path="/" component={ArchiveTable} />

        <Switch>
          <Route path="/diff/:metadataId/:archiveId" component={MetadataDiff} />
          <Route path="/diff/:archiveId" component={ArchiveDiff} />
          <Route path="/archive/:archiveId" component={ArchiveDiff} />
        </Switch>
      </Router>
    </>
  );
}

export default hot(App);
