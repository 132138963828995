import React, { useEffect, useState, ReactElement } from 'react';

import ReactDiffViewer from 'react-diff-viewer';
import {
  getWorkspaceMetadata,
  getDataONEMetadata
} from 'service/MetadataService';
import FullPageLoader from 'components/loading/FullPageLoader';
import Archive from 'types/Archive';
import styled from 'styled-components';

const color = 'color: #24292e;';

const Removal = styled.span`
  background-color: #ffeef0;
  ${color}
`;

const Addition = styled.span`
  background-color: #e6ffed;
  ${color}
`;

interface Props {
  archive: Archive;
}

export default function MetadataDiff({ archive }: Props): ReactElement {
  const metadataPid = archive.metadatapid;
  const metadataId = archive.folder.metadataId;

  const [state, setState] = useState({
    rwMetadata: '',
    archivedMetadata: '',
    loaded: false
  });

  useEffect(() => {
    const getMetadata = async (): Promise<void> => {
      const rwMetadata = await getWorkspaceMetadata(metadataId);
      const archivedMetadata = await getDataONEMetadata(metadataPid);

      setState({
        rwMetadata,
        archivedMetadata,
        loaded: true
      });
    };

    getMetadata();
  }, [metadataId, metadataPid]);

  const { rwMetadata, archivedMetadata, loaded } = state;

  if (!loaded) {
    return <FullPageLoader />;
  }

  if (archivedMetadata === rwMetadata) {
    return (
      <div>
        <h2>no metadata changes</h2>
      </div>
    );
  }

  return (
    <div>
      <h2>
        Metadata changed: <Addition>{rwMetadata.length}</Addition> (Workspace)
        vs <Removal>{archivedMetadata.length}</Removal> (Archive) lines
      </h2>
      <ReactDiffViewer
        oldValue={archivedMetadata}
        newValue={rwMetadata}
        splitView={false}
        disableWordDiff={true}
      />
    </div>
  );
}
