import React, { FC } from 'react';
import { CSVLink } from 'react-csv';

const headers = [
  { label: 'id', key: 'id' },
  { label: 'doi', key: 'doi' },
  { label: 'citation', key: 'citation' },

  { label: 'project id', key: 'project.id' },
  { label: 'project', key: 'project.name' },

  { label: 'folder id', key: 'folder.id' },
  { label: 'folder', key: 'folder.title' },

  { label: 'indexed', key: 'indexed' },
  { label: 'submitted', key: 'submitted' },

  { label: 'type', key: 'type' },
  { label: 'url', key: 'url' }
];

export interface Props {
  data: object[];
}

const DownloadLink: FC<Props> = ({ data }) => {
  return (
    <CSVLink filename={'archives.csv'} headers={headers} data={data}>
      Download CSV
    </CSVLink>
  );
};
export default DownloadLink;
