import log from 'loglevel';

if (
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development' ||
  localStorage.getItem('logging') === 'true'
) {
  log.enableAll();
}

export default log;
