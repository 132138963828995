import React, { useState, useEffect, ReactElement } from 'react';
import log from 'util/log';

import { RouteComponentProps } from 'react-router-dom';
import FolderDiff from './FolderDiff';
import { getArchive } from 'service/WorkspaceService';
import Archive from 'types/Archive';
import FullPageLoader from 'components/loading/FullPageLoader';
import ArchiveHeader from 'components/header/ArchiveHeader';
import MetadataDiff from './MetadataDiff';

interface Props {
  archiveId: string;
}

export default function ArchiveDiff({
  match: {
    params: { archiveId }
  }
}: RouteComponentProps<Props>): ReactElement {
  const [state, setState] = useState({
    archive: null as Archive | null,
    loaded: false
  });

  useEffect(() => {
    const load = async (): Promise<void> => {
      const archive = await getArchive(archiveId);

      log.info('loaded archive %o', archive);

      const { doi, citation } = archive;

      document.title = `${doi} | ${citation}`;

      setState({
        archive,
        loaded: true
      });
    };

    load();
  }, [archiveId]);

  const { archive, loaded } = state;

  if (!loaded || !archive) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <ArchiveHeader archive={archive} />
      <FolderDiff archiveId={archiveId} />
      <MetadataDiff archive={archive} />
    </div>
  );
}
