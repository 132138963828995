import React, { ReactElement } from 'react';
import styled from 'styled-components';

// @ts-ignore
import Spinner from 'react-spinkit';

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
`;

export default function FullPageLoader(): ReactElement {
  return (
    <Container>
      <Spinner name="line-scale" />
    </Container>
  );
}
