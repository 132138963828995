import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import File from 'types/File';

import styled from 'styled-components';
import overflowHidden from 'styles/overflow-hidden';

const List = styled.ul`
  list-style: none;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
`;

const Field = styled.span`
  display: inline-block;
  ${overflowHidden}
`;

const Name = styled(Field)`
  width: 400px;
`;

const Size = styled(Field)`
  width: 100px;
`;

const Created = styled(Field)`
  width: 200px;
`;

interface Props {
  files: File[];
  className?: string;
}

export default function FileList({ files, className }: Props): ReactElement {
  return (
    <List className={className}>
      {files.map(function (file, index) {
        const { fileName, created, size } = file;

        const formattedCreated = dayjs(created).format('M/DD/YYYY');

        return (
          <li key={index}>
            <Name>{fileName}</Name>
            <Size>{size} </Size>
            <Created>{formattedCreated}</Created>
          </li>
        );
      })}
    </List>
  );
}
