import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { FC } from 'react';

const { RangePicker } = DatePicker;

const onChange = (
  values: [Moment, Moment],
  onChangeExternal: onChangeEvent | void
): void => {
  if (!values) {
    if (onChangeExternal) {
      onChangeExternal({ start: '', end: '' });
    }
    return;
  }

  const start = values[0].format('YYYY-MM-DD');
  const end = values[1].format('YYYY-MM-DD');

  if (onChangeExternal) {
    onChangeExternal({ start, end });
  }
};

export interface DateFilterRange {
  start: string | void;
  end: string | void;
}

type onChangeEvent = (params: DateFilterRange) => void;

interface Props {
  onChange?: onChangeEvent;
  className?: string;
}

const DateFilter: FC<Props> = ({ className, onChange: onChangeExternal }) => {
  const tomorrow = moment().add(1, 'day');
  return (
    <RangePicker
      className={className}
      ranges={{
        Today: [moment(), tomorrow],
        'Past week': [moment().subtract(1, 'week'), tomorrow],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Past three months': [moment().subtract(3, 'months'), tomorrow],
        'Past six months': [moment().subtract(6, 'months'), tomorrow],
        'Past year': [moment().subtract(1, 'year'), tomorrow]
      }}
      // @ts-ignore
      onChange={values => onChange(values, onChangeExternal)}
    />
  );
};
export default DateFilter;
