import { CaretLeftFilled } from '@ant-design/icons';
import { History } from 'history';
import React, { ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button`
    text-decoration:none;
    font-size:1em;
    height:32px;

    background-color:#76a539

    border-radius:5px;

    //above sidebar and click-prevent
    z-index:60;
    border:none;

    height:48px;
    width:48px;
    padding:15px;

    outline:none;

    cursor:pointer;
    &:hover {
        background-color:#579c36;
    }
    &:active {
        box-shadow: inset 0 0 5px #424242;
    }
`;

const Icon = styled(CaretLeftFilled)`
  font-size: 32px;
  position: relative;
  top: -6px;
  left: -6px;

  border-radius: 5px;
`;

interface Props {
  className?: string;
  history: History;
}

const BackButton = ({ className, history }: Props): ReactElement => {
  return (
    <Button className={className} onClick={(): void => history.push('/')}>
      <Icon />
    </Button>
  );
};

export default withRouter(BackButton);
