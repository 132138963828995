import { Input } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const { Search: SearchInput } = Input;

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = { filter: props.filter };
  }

  handleChange(e) {
    this.props.onFilterChange(e.target.value);
  }

  componentDidMount() {
    this.filterInput.focus();
  }

  render() {
    const filter = this.state.filter;

    return (
      <SearchInput
        style={{ width: '200px', marginRight: '15px' }}
        className="search"
        placeholder="Search"
        ref={input => {
          this.filterInput = input;
        }}
        value={filter}
        onChange={this.handleChange}
        allowClear
      />
    );
  }
}

Search.propTypes = {
  filter: PropTypes.string,
  onFilterChange: PropTypes.func
};
