import React, { useEffect, useState, ReactElement } from 'react';
import styled from 'styled-components';

import File from 'types/File';

import FullPageLoader from 'components/loading/FullPageLoader';
import { getObsoleteFiles } from 'service/WorkspaceService';
import FileList from 'components/diff/FileList';

const Title = styled.h1`
  font-size: 1.2em;
`;

const Files = styled(FileList)`
  margin-left: 1em;
`;

interface Props {
  archiveId: string;
}

export default function FolderDiff({ archiveId }: Props): ReactElement {
  const [state, setState] = useState({
    files: [] as File[],
    loaded: false
  });

  useEffect(() => {
    const getFiles = async (): Promise<void> => {
      const files = await getObsoleteFiles(archiveId);

      setState({
        files,
        loaded: true
      });
    };

    getFiles();
  }, [archiveId]);

  const { files, loaded } = state;

  if (!loaded) {
    return <FullPageLoader />;
  }

  return (
    <div>
      <Title>{files.length} files modified</Title>
      <Files files={files} />
    </div>
  );
}
