import { getString } from './AxiosService';

export const getDataONEMetadata = async (pid: string): Promise<string> => {
  // escape slash characters (10.24431/rw1k21g)
  pid = pid.replace('/', '%2f');

  const url = `https://dataone.researchworkspace.com/mn/v2/object/${pid}`;

  return getString(url);
};

export const getWorkspaceMetadata = async (id: string): Promise<string> => {
  const url = `https://researchworkspace.com/api/metadata/export/${id}/19115`;

  return getString(url);
};
